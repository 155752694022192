import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import styles from './gallery.module.css'
import Layout from "../components/layout"
import LayoutSimple from "../components/layout-simple"
import GalleryPreview from '../components/gallery-preview'


class GalleryIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const galleries = get(this, 'props.data.allContentfulPhotoGallery.edges')

    return (
      <LayoutSimple location={this.props.location} >
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />

          <div className="wrapper">
            <h2 className="section-headline">Labradoodle Photo Galleries</h2>
            <ul className={styles.galleryList }>
              {galleries.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    { console.log(node) }
                    <GalleryPreview gallery={node} />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </LayoutSimple>
    )
  }
}

export default GalleryIndex

export const pageQuery = graphql`
  query GalleryIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPhotoGallery(sort: {fields: weight, order: ASC}, filter: { node_locale: {eq: "en-US"}, public: {eq: true}}) {
      edges {
        node {
          title {
            id
            title
          }
          slug
          updatedAt(formatString: "MMMM DD, YYYY")
          tags
          coverImage {
            fluid(maxWidth: 400, maxHeight: 400, quality: 100) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            id
            childMarkdownRemark {
              html
            }
          }

        }
      }
    }
  }
`
